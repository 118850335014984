<template>
  <div>
    <div
      v-if="fetchData.length > 0"
      class="pb-3"
    >
      <!-- 項目 -->
      <b-card
        v-for="(data, index) in fetchData"
        :key="index"
        no-body
        class="mx-2 mx-sm-5"
        :class="index == 0 ? 'mt-3' : 'mt-1'"
        @click="onRowSelected(data)"
      >
        <!-- user suggestion  -->
        <div
          :class="{'border-danger': isBorder(data)}"
          class="d-flex justify-content-start align-items-start p-75"
        >
          <!-- <b-avatar :src="data.image" class="mr-50" size="60" /> -->
          <b-img
            :src="data.image || 'https://upload.cc/i1/2021/02/13/xrhwRD.jpg'"
            rounded
            fluid
            class="mr-50 width-100 height-100"
            style="object-fit: cover;"
          />
          <div class="user-page-info">
            <h4 class="font-weight-bolder mb-0">
              {{ data.name }}
            </h4>
            <small class="text-muted">{{ data.name }}</small>
          </div>

          <div
            style="position: absolute; right: 10px; bottom: 5px;"
            class="btn-icon ml-auto"
          >
            <!-- 180 min/$1000起 -->
            <small class="text-muted">
              {{ data.interval }} min
            </small>
            <span
              v-if="data.price > 0"
              class="h5 font-weight-bolder text-danger"
            > / ${{ data.price }}起
            </span>
          </div>

          <div
            style="position: absolute; right: -10px; top: -10px;"
            class="btn-icon ml-auto"
          >
            <b-button
              variant="danger"
              class="btn-icon ml-auto p-0"
              style="width:40px; height:40px; "
            >
              <feather-icon
                v-if="isBorder(data)"
                icon="CheckCircleIcon"
              />
              <span v-else>
                預約
              </span>
            </b-button>
          </div>
        </div>
        <!--/ user suggestion  -->
      </b-card>
    </div>

    <div
      v-if="fetchData.length > 0"
      style="position: fixed; bottom: 0;"
      class="w-100"
    >
      <div class="mx-2">
        <b-button
          variant="gradient-dark"
          block
          :to="{ name: 'apps-reserve-v2-submit', params: { slug: $route.params.slug, code: $route.params.code } }"
        >
          下一步
        </b-button>
      </div>
    </div>

    <div v-if="fetchData.length === 0">
      <b-card
        style="background: hsla(0,0%,80%,.4);"
        class="m-4"
      >
        <span>
          該服務顧問尚無有任何預約項目
        </span>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  // BTable,
  // BTableSimple, BTr, BTd, BTbody, BTfoot,
  BCard, BImg, BButton,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'

//
import dataStoreModule from './reserveStoreModule'

export default {
  components: {
    // BTable,
    // BTableSimple,
    // BTr,
    // BTd,
    // BTbody,
    // BTfoot,

    BCard,
    BImg,
    BButton,
  },
  setup(_, { root }) {
    const APP_STORE_MODULE_NAME = 'app-reserve-page'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dataStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // Use Func
    const fetchData = ref([])
    const fetchAll = () => {
      store.dispatch(`${APP_STORE_MODULE_NAME}/fetchPersonnelService`, {
        slug: root.$route.params.slug,
        code: root.$route.params.code,
      })
        .then(response => {
          fetchData.value = response.data
        })
    }

    fetchAll()

    const reserveServices = computed(() => store.state.saleOrder.reserve_services)

    const onRowSelected = item => {
      const selectedIndex = reserveServices.value.findIndex(s => s.id === item.id)
      if (selectedIndex === -1) {
        reserveServices.value.push(item)
      } else {
        reserveServices.value.splice(selectedIndex, 1)
      }
    }

    const isBorder = item => reserveServices.value.findIndex(i => i.id === item.id) !== -1

    return {
      fetchData,
      onRowSelected,
      isBorder,
    }
  },
}
</script>

<style lang="scss">
.card:hover {
  background-color: #f6f6f9;
}
[dir] .table-hover tbody tr:hover {
  background-color: #f6f6f9;
}
</style>
